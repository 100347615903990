@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #F8F8F8;
  font-weight: 450;
  color: #414141;
}

@layer components{
  .page-title{
      @apply md:text-[1.8em] font-[700] text-black text-[1.5em] font-['Merriweather']
  }
  .page-outer{@apply p-8 bg-white rounded}
  .container{
    @apply mx-auto max-w-screen-lg p-10 lg:p-4
}
  .input{@apply w-full rounded-[5px] h-[49px] px-5 relative flex items-center text-[14px] border border-[#E7E7E7] font-[450] placeholder:text-light-black}
  .label{@apply text-[#252631] font-[500] text-[14px]}
  .pryBtn{@apply bg-primary input justify-center px-5 font-[600] text-[14px] hover:bg-secondary text-white space-x-2 }
  .pryBtnOutline{@apply pryBtn w-full bg-transparent border border-primary hover:bg-primary text-primary hover:text-white}
}

@keyframes moveLine {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(100%);
  }
}

.loading-line {
  width: 100%;
  animation: moveLine 5s linear infinite;
}
.notification-section a{color: #0099CE; text-decoration: underline;}
.notification-section p{margin-bottom: 10px;}

.react-datepicker__input-container{
  position: inherit !important;
}
/* .react-datepicker__month-container{
  position: relative !important;
  z-index: 200 !important;
} */

/* Remove the spinner on the number input */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove the spinner in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
/* Hide default arrow and add custom icon */
/* Hide default dropdown arrow */
select {
  -webkit-appearance: none;  /* Safari and Chrome */
  -moz-appearance: none;     /* Firefox */
  appearance: none;          /* Standard syntax */
  background: transparent;   /* Hide the background */
  padding-right: 20px;       /* Add space for custom icon */
}

